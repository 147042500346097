import { useEffect, useState } from "react";
import axiosInstance from "../../axios.instance";
import { useConfig } from "../../configuration/useConfig";
declare global {
  interface Window {
    ApplePaySession?: typeof ApplePaySession;
  }
}

declare const ApplePaySession: {
  new (version: number, paymentRequest: ApplePayPaymentRequest): any;
  canMakePayments(): boolean;
  STATUS_SUCCESS: number;
  STATUS_FAILURE: number;
};
interface ApplePayPaymentRequest {
  countryCode: string;
  currencyCode: string;
  supportedNetworks: string[];
  merchantCapabilities: ("supports3DS" | "supportsCredit" | "supportsDebit")[];
  total: {
    label: string;
    amount: string;
  };
  requiredShippingContactFields: string[];
  requiredBillingContactFields: string[];
}

const ApplePayButton = ({ amount, onPaymentSuccess }) => {
  const [canUseApplePay, setCanUseApplePay] = useState(false);
  const { config } = useConfig();
  useEffect(() => {
    if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
      setCanUseApplePay(true);
    }
  }, []);
  const handleApplePay = async () => {
    const paymentRequest: ApplePayPaymentRequest = {
      countryCode: "US",
      currencyCode: "USD",
      supportedNetworks: ["visa", "masterCard", "amex", "discover"],
      merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
      total: {
        label: "Tivity",
        amount: "0.01",
      },
      requiredShippingContactFields: ["postalAddress"],
      requiredBillingContactFields: ["postalAddress"],
    };
    const session = new ApplePaySession(6, paymentRequest);

    // session.onvalidatemerchant = async (event) => {
    //   // Validate merchant with Apple servers
    //   const validationURL = event.validationURL;
    //   console.log("validationURL", validationURL);
    //   const response = await fetch("/api/apple-pay/validate-merchant", {
    //     method: "POST",
    //     body: JSON.stringify({ validationURL }),
    //     headers: { "Content-Type": "application/json" },
    //   });
    //   console.log("response", response);
    //   const { merchantIdentifier } = await response.json();
    //   console.log("merchantIdentifier", merchantIdentifier);
    //   session.completeMerchantValidation(merchantIdentifier);
    // };

    // session.onvalidatemerchant = async (event) => {
    //   // Validate merchant with Apple servers
    //   const validationURL = event.validationURL;
    //   console.log("validationURL", validationURL);
    //   try {
    //     const response = await axiosInstance.post(
    //       "/api/apple-pay/validate-merchant",
    //       {
    //         validationURL,
    //       }
    //     );
    //     console.log("response", response);
    //     const { merchantSessionIdentifier } = response.data;
    //     console.log("merchantSessionIdentifier", merchantSessionIdentifier);
    //     session.completeMerchantValidation(merchantSessionIdentifier);
    //   } catch (error) {
    //     console.error("Error validating merchant:", error);
    //     session.abort(); // Abort the session in case of an error
    //   }
    // };

    session.onvalidatemerchant = async (event) => {
      // Validate merchant with Apple servers
      // const validationURL = event.validationURL;
      console.log("add this for new build");
      let validationURL = event.validationURL;
      if (config["environment"] !== "prod") {
        validationURL =
          "https://apple-pay-gateway-cert.apple.com/paymentservices/startSession";
      }
      console.log("validationURL", validationURL);
      try {
        const response = await axiosInstance.post(
          "/api/apple-pay/validate-merchant",
          {
            validationURL,
          }
        );
        console.log("response", response);
        const merchantData = response.data;
        console.log("merchantSessionIdentifier", merchantData);
        session.completeMerchantValidation(merchantData);
      } catch (error) {
        // Log error safely
        console.error(
          "Error validating merchant:",
          (error as any)?.message || error
        );
        console.error("Full error details:", error);
        session.abort(); // Abort the session in case of an error
      }
    };

    session.onpaymentauthorized = async (event) => {
      // Send payment token to backend
      console.log("event", event);
      const { payment } = event;
      console.log("payment", payment);
      console.log("payment.token", payment.token);
      console.log("payment.token.paymentData", payment.token.paymentData);
      const response = await fetch("/api/apple-pay/process-payment", {
        method: "POST",
        body: JSON.stringify({ token: payment.token }),
        headers: { "Content-Type": "application/json" },
      });
      const { success } = await response.json();
      if (success) {
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        onPaymentSuccess();
      } else {
        session.completePayment(ApplePaySession.STATUS_FAILURE);
        console.log("Payment failed");
      }
    };
    session.begin();
  };
  return canUseApplePay ? (
    <button
      onClick={handleApplePay}
      data-label="Checkout with Apple Pay"
      style={{
        WebkitAppearance: "-apple-pay-button",
        appearance: "-apple-pay-button" as any,
        display: "inline-block",
        width: "50%",
        height: "44px",
      }}
    />
  ) : // <button
  //   className="apple-pay-button apple-pay-button-black"
  //   data-label="Checkout with Apple Pay"
  //   onClick={handleApplePay}
  // ></button>
  null;
};
export default ApplePayButton;
